.logInContainer {
  padding: 0;
  display: inline-block;

  @media screen and (max-width: 32rem) {
    margin-right: 0.5rem;
  }

  .logInButton {
    margin: 5px 0 5px 0.5rem;
    z-index: 0;

    i {
      margin: -2px 0 0 -6px;
      vertical-align: middle;
    }
  }
}
