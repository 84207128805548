.header {
    margin-top: 5px;
    margin-bottom: -30px; /* trade off for header:after */
    max-width: 32rem;
    text-align: justify;

    @media screen and (min-width: 33em) {
        margin: 5px 0.5rem -30px 0.5rem;
    }

    nav {
        display: inline-block;
        vertical-align: middle;

        i {
            margin: 0 5px -2px;
            vertical-align: text-bottom;
        }

        .link {
            padding: 7px 0;
        }
    }
}

.header:after{
    content: '';
    display: inline-block;
    width: 100%;
    height: 0;
    font-size:0;
    line-height:0;
}
