.loginContainer {
  max-width: 32rem;
  margin-top: 10px;
  text-align: center;

  @media screen and (min-width: 33em) {
    margin: 10px 0.5rem;
  }

  .panel {
    text-align: left;
  }

  button.provider {
    padding: 0 10px;
    min-width: 15.3rem;
    text-align: left;

    i {
      margin: -3px 0.3em 0 0;
      vertical-align: middle;
    }

    .loginIcon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.5em;

      svg {
        width: 24px;
        height: 24px;
        vertical-align: text-bottom;
        margin-top: 6px;
      }
    }
  }

  .balticStar {
    background-color: #F79C10;
    color: #0b366b;
  }
  .balticStar:hover {
    background-color: #F8A629;
  }
}
