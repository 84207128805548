.error {
  margin-top: -15px;
}

.hidden {
  visibility: hidden;
}

.actions {
  text-align: right;
}

.table {
  display: table;
  width: 100%;

  .row {
    display: table-row;

    .cell {
      display: table-cell;
    }
  }
}
