@import "muicss/lib/sass/mui";

html,
body {
    height: 100%;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 12pt;
    color: mui-color('black');
}

h2 {
    margin-left: 5px;
    font-size: 1.5rem;
}

h3 {
    margin-left: 5px;
    font-size: 1.2rem;
}
