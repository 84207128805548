.localeSwitcher {
    padding: 0;
    display: inline-block;
    vertical-align: middle;

    @media screen and (max-width: 32rem) {
        margin-right: 0.5rem;
    }

    i {
        margin: 0 5px;
        vertical-align: middle;
    }

    button {
        padding: 0 10px;
    }

    ul {
        min-width: 7.5em;
        cursor: pointer;

        .selected > a {
            font-weight: bold;
        }
    }
}
