.header {
  margin-top: 5px;
  margin-bottom: -30px; /* trade off for header:after */
  max-width: 32rem;
  text-align: justify;

  @media screen and (min-width: 33em) {
    margin: 5px 0.5rem -30px 0.5rem;
  }

  .title {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
  }
}

.header:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 0;
  font-size: 0;
  line-height: 0;
}

.container {
  max-width: 32rem;
  text-align: justify;
  margin-left: 0.5rem;

  .selector {
    margin-right: 5px;
  }

  ul {
    cursor: pointer;
  }

}


.table {
  display: table;
  width: 100%;

  .row {
    display: table-row;

    .cell {
      display: table-cell;
    }
  }
}